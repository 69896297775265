import { Typography, useTheme } from "@mui/material";
import {
  BLACK,
  CLOUD_BURST,
  DARK_BLUE_GRAY,
} from "../../../../constants.js/index.js";
import { useBreakpoints } from "react-match-breakpoints";
import { Link } from "react-router-dom";
import VButton from "../../../../components/V/VButton/index.js";

export const Content = ({ to, title, description }) => {
  const theme = useTheme();
  const { tabletDown } = useBreakpoints();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: tabletDown ? "100%" : 891,
        gap: theme.spacing(2),
      }}
    >
      <VButton
        variant="text"
        showDot={false}
        component={to ? Link : null}
        {...(to && {
          to,
          onClick: () => {
            setTimeout(() => {
              window.scrollTo(
                {
                  top: 0,
                  behavior: "smooth",
                },
                0
              );
            });
          },
        })}
        style={{
          textAlign: "center",
          fontSize: tabletDown ? 35 : 54,
          fontWeight: 700,
          color: CLOUD_BURST,
        }}
      >
        {title}
      </VButton>
      <Typography
        style={{
          textAlign: "center",
          color: DARK_BLUE_GRAY,
          maxWidth: tabletDown ? 500 : 749,
          fontSize: 18,
        }}
      >
        {description}
      </Typography>
    </div>
  );
};
