import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const useAnchorScroll = () => {
  const { hash } = useLocation();

  useEffect(() => {
    // Check if there is a hash and if an element with that id exists
    const el = hash && document.getElementById(hash.replace("#", ""));
    if (el) {
      el.scrollIntoView({ block: "center", behavior: "smooth" });
      window.history.pushState("", document.title, window.location.pathname);
    }
  }, [hash]); // Fires every time hash changes
  return;
};
