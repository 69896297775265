const enLang = {
  main: "Network of physiotherapists",
  physiotherapists: "physiotherapists",
  bookSession: {
    name: "Book a session",
    talkWithPhysioterapist: "Schedule your free evaluation",
    talkWithPhysioterapistLink: "https://forms.gle/NgMVPUuZNxRPLVJk9",
    evaluation: {
      title: "Book your free evaluation",
    },
  },
  header: {
    home: "Home",
    services: "Services",
    aboutUs: "About us",
    pricing: "Pricing",
    location: "Location",
    blog: "Blog",
    recomendations: "Recomendations",
    therapist: "Therapist",
  },
  reservationFormLink: "https://forms.gle/LeAYRuL5GAU218pu8",
  makeReservation: "Make your reservation",
  rehabilitation: "Start your rehabilitation process!",
  servicesHeader: "Services",
  recomendationsHeader: "Product recommendations",
  aboutUsHeader: "About us",
  pricingHeader: "Pricing",
  teamHeader: "Team",
  aboutUsText: `Ventura Cuida is your network of therapists, with physiotherapists, masseurs and specialists in traditional Chinese medicine, always at your disposal.
  Our specialists, with extensive knowledge in the various therapeutic areas, are ready to solve your problems and those of your family.
  At Ventura Cuida, we are not content with just treating symptoms, but with finding and resolving the cause, aiming to comprehensively improve your health. In a busy world, we seek to facilitate access to a unique treatment experience, taking care of your body and promoting a healthy lifestyle.
  We are a young company, dedicated to the positive impact we can have on people's lives.`,
  aboutAna: `
  Ana Sofia Ventura is a physiotherapist specializing in women's health, focusing on pelvic physiotherapy and lymphatic drainage. Graduated in Physiotherapy from the Escola Superior de Tecnologia da Saúde de Lisboa, in 2019, and postgraduate in Maternal and Child Physiotherapy at Egas Moniz, in 2021.
Throughout her career, Ana Sofia has worked in spas, beauty clinics, physiotherapy clinics and mother and child centers in pelvic, dermatofunctional and generalized physiotherapy. She is also a trainer in professional schools.
He completed extracurricular internships at the Ospedale Maggiore di Novara in conjunction with the Università Del Piemonte Orientale where he improved his lymphatic drainage techniques and knowledge of urinary incontinence and pain during sexual intercourse. With a great interest in social problems, he also dedicated his time to volunteering in physiotherapy in Guatemala.
In 2022, he founded Ventura Cuida, an initiative that expands access to health care and well-being. In addition to pelvic and dermatofunctional physiotherapy, it offers services such as therapeutic, relaxing massage and lymphatic drainage and orthopedic and neurological physiotherapy.
Ventura Cuida, in addition to being led by Ana Sofia, has other therapists who are passionate about their areas of specialization. This diversity of talent reflects the expansion of Ana Sofia's vision of personalized and welcoming healthcare, accessible to everyone. Ventura Cuida's mission is to enhance the power of change in people's lives, working to achieve specific goals with a holistic approach.
  `,
  schedule: {
    name: "Schedule your session",
  },
  more: "More",
  services: {
    ourServices: "Our Services",
    enhance: "Enhance well-being with our personalized services.",
    pelvic: {
      name: "Pelvic Phisiotherapy",
      description:
        "Experience the healing benefits of Pelvic Physiotherapy. Our specialized therapists provide personalized care to address pelvic floor dysfunction, improve bladder control, and enhance pelvic health. Take charge of your well-being and regain confidence. Book a session now and start your journey towards optimal pelvic health.",
    },
    neurology: {
      name: "Neurologic Phisiotherapy",
      description:
        "Unlock your potential with Neurologic Physiotherapy. Our expert therapists specialize in treating neurological conditions, helping you regain function, improve mobility, and enhance your overall quality of life. Through tailored exercises and innovative techniques, we'll guide you on a journey towards optimal neurological health. Take control of your well-being and embrace the possibilities. Book a session now and discover the transformative effects of Neurologic Physiotherapy.",
    },
    respiratory: {
      name: "Respiratory Phisiotherapy",
      description:
        "Breathe easier with Respiratory Physiotherapy. Our dedicated therapists specialize in respiratory conditions, providing personalized care to improve lung function, manage symptoms, and enhance your respiratory well-being. Experience tailored techniques and exercises designed to optimize your breathing and overall lung health. Take control of your respiratory health. Book a session now and discover the benefits of Respiratory Physiotherapy for a better quality of life.",
    },
    orthopedic: {
      name: "Orthopedic Phisiotherapy",
      description:
        "Experience the transformative power of Orthopedic Physiotherapy. Our skilled therapists specialize in musculoskeletal conditions, helping you recover from injuries, manage pain, and restore optimal movement. With personalized treatment plans and advanced techniques, we'll guide you towards a pain-free and active life. Take control of your orthopedic health. Book a session today and embark on a journey to renewed strength and mobility.",
    },
    pediatric: {
      name: "Pediatric Phisiotherapy",
      description:
        "Unlock the full potential of your child with Pediatric Physiotherapy. Our dedicated therapists provide personalized care to address developmental challenges, promote motor skills, and enhance overall well-being. Witness your child's growth and progress with our expert guidance. Book a session now and give your child the gift of a brighter future through Pediatric Physiotherapy.",
    },
    geriatric: {
      name: "Geriatric Phisiotherapy",
      description:
        "Embrace the benefits of Geriatric Physiotherapy for enhanced mobility and quality of life. Our experienced therapists specialize in addressing age-related challenges, promoting strength, balance, and independence. Experience personalized care that focuses on your unique needs and goals. Take a proactive approach to your well-being. Book a session now and rediscover the joy of movement in your golden years.",
    },
    therapeuticMassage: {
      name: "Therapeutic Massage",
      description:
        "Experience the healing power of therapeutic massage, a rejuvenating practice that targets muscle tension, stress, and promotes overall well-being. Let our skilled therapists melt away your worries and restore balance to your body and mind. Treat yourself to a blissful escape and discover the transformative benefits of therapeutic massage. Book your session now and embark on a journey of relaxation and revitalization. Your body deserves it!",
    },
    pilates: {
      name: "Clinical Pilates and Accompanied Exercise",
      description:
        "Discover the transformative effects of Clinical Pilates and Accompanied Exercise. Strengthen your core, improve posture, and enhance overall fitness under the guidance of our experienced instructors. Unlock your body's full potential and experience the joy of movement. Join us for a session and embark on a journey of health and vitality. Elevate your fitness routine today!",
    },
    lymphaticDrainage: {
      name: "Lymphatic Drainage",
      description:
        "Experience the gentle yet powerful benefits of lymphatic drainage massage. This specialized technique promotes detoxification, boosts the immune system, and reduces swelling. Treat yourself to a soothing session that leaves you feeling refreshed and revitalized. Embrace the transformative effects of lymphatic drainage massage. Book now and enhance your well-being.",
    },
  },
  pricing: {
    quote: "Price Inquiry",
    priceQuote: "Price on request",
    session: "1h Session",
    perSession: "/1h session",
    description1:
      "All our services, from massage and lymphatic drainage to rehabilitation services have the same price because we consider them all to be part of physical therapy.",
    description2:
      "Our sessions have a duration of 1h. If you would like a session with a different duration than our usual period, please contact us so we can find the best possible combination.",
    oneSession60: {
      name: "One session",
      price: "40",
      duration: "60",
      ideal: {
        1: "Urgent treatment",
        2: "Try the service",
        3: "Teaching session",
      },
    },
    pack5: {
      name: "Pack five sessions",
    },
    pack10: {
      name: "Pack ten sessions",
      price: "38",
      duration: "60",
      ideal: {
        1: "Sporadic treatment",
        2: "Dealing with an acute problem",
      },
    },
    pack20: {
      name: "Pack twenty sessions",
      price: "36",
      duration: "60",
      ideal: {
        1: "Intensive Treatment",
        2: "Dealing with a chronic situation",
      },
    },
  },
  team: {
    executiveDirector: {
      name: "Ana Sofia Ventura",
      role: "Physiotherapist and Founder",
      quote: `Henry Ford wrote that “When everyone advances together, success happens by itself”. Our success is the success of your treatment.`,
      description: `Your needs concern me, just as I am 
        concerned about the work of physical therapists. 
        My job is to bridge the gap between the two and 
        find the best balance possible, so that we can all achieve our goals`,
    },
    technicalDirector: {
      name: "Technical Director",
      description:
        "I am a computer engineer. My mission is to take care of the platform so that it is as accessible as possible for you. My job is to make your job easier.",
      role: "Engineer",
    },
    ourPhysiotherapist: {
      name: "Our Physiotherapist",
      description:
        "Our network of physical therapists is pre-selected. Only physiotherapists registered in the Order of Physiotherapists are admitted. All clients carry out an evaluation of the physiotherapists' performance, after the conclusion of the treatment. Taking into account the evaluation rankings of physiotherapists and depending on the clinical condition, we selected the physiotherapist whose characteristics best suit your needs.",
    },
  },
  location: {
    name: "Location",
    lisbon: "Lisbon",
    madeira: "Madeira",
    oporto: "OPorto",
    noLocation: {
      question: "Couldn't find your location?",
      foundPhysio: "We will find a physiotherapist near you.",
      contactUs: "Contact us",
    },
    closeToYou:
      "Enjoy home care services in Lisbon and Madeira! Schedule now for maximum convenience and relaxation without leaving your home. We'll take care of you wherever you are!",
    ourLocation: "Our location",
  },
  partnerships: "Our partnerships",
  blog: {
    whyPhisioterapy: {
      img: "/images/blog/why-physio.webp",
      createdAt: "28/07/2022",
      createdBy: "Phisioterapist Ana Sofia Ventura",
      title: "Why do physiotherapy at home?",
      text: `Today we are more and more connected to the culture of our body.
        It is often said that our body is our temple, it is our home.
        Unfortunately, we have less and less time available in our daily lives.
        We live a busy life. We eat poorly, fast. We don't have time to exercise.
        We spend all day in uncomfortable positions, too much time standing or too much time sitting.
        We find it increasingly difficult to take care of our temple and end up prioritizing other aspects of our lives.
        We end up not taking care of something that should be sacred. We have the awareness of its importance but not the availability and accessibility to take care of it.
        Due to the lifestyle that we have nowadays, many times our health goes to the background, because there is no time to take care of it.
        to second plan, because there is no time to include treatments in our daily routine. By
        physiotherapy at home can be the solution.
        <br><br><b>With home physiotherapy it is possible to save</b>- time and money<br><br>
        Doing physiotherapy at home allows you to avoid wasting time in waiting lines and
        unnecessary travel.
        Physiotherapy at home is even the easiest way to perform physiotherapy
        you don't have to spend money in travel, and you can take the opportunity to make other
        investments
        <br><br><b>Physiotherapy at home is personalized</b><br><br>
        During your physical therapy session, this is your space to yourself. You will not have to share the
        the physiotherapist's time with other clients and you will have all the attention and
        personalization so that your treatment is the best and most effective.
        <br><br><b>Physiotherapy at home is convenient and comfortable</b><br><br>
        It also allows the client to be in their safe and comfortable space. By being in the
        comfort of your home, your treatment will be adapted to your day-to-day reality and so
        even when you start your maintenance period, you will have all the tools you need for it.
        for it.
        <br><br><b>Physiotherapy at home allows you flexibility</b><br><br>
        You can schedule the session at a time that is most convenient for you, as you will not be
        dependent on the availability of a physical space.
        <br><br><b>Physiotherapy at home allows you to receive family support</b><br><br>
        Your family can be present during your treatment if you wish, understand your needs and help you as needed.
        understand your needs and help you in any way necessary.
        <br><br><b>Physiotherapy at home is safe</b><br><br>
        Decrease your chances of contracting an infection. You don't need to meet with
        other patients or health care professionals.`,
    },
    massage: {
      img: "/images/blog/massage.webp",
      createdAt: "30/07/2022",
      createdBy: "Phisioterapist Ana Sofia Ventura",
      title: "Massage vs Drainage",
      text: `Many people know these terms and often use them synonymously.
        But do they know the differences?<br/><br/>
        Although both have excellent results, there are two manual techniques used
        with different goals.<br/><br/>
        Massage works on various body systems, such as the musculoskeletal system,
        because it mobilizes structures such as muscles and the circulatory system, for example, through the
        increased circulation. There are several types of massages, with different goals. THE
        Modeling massage, as the name implies, has the objective of modeling.<br/><br/>
        On the other hand, lymphatic drainage has only the objective of working the
        lymphatic and circulatory. Lymphatic drainage aims to reduce edema.<br/><br/>
        There are many methods of lymphatic drainage. In general, the drainage method
        lymphatic system used by physical therapists is the Leduc method. This method does not use cream
        so that there can be a traction caused by direct contact between the hands and the skin of the
        surface to be drained. The skin has filaments that are connected to the lymphatic vessels, and so,
        by moving the skin, it is possible to mobilize these vessels. The speed is always slow, because the
        The speed at which the lymph circulates is also slow and the pressure is always gentle, so that it does not
        there is closure of the lymphatic vessels and so that the lymph can progress naturally.<br/><br/>
        The modeling massage can often have a high speed and a
        strong pressure because they have the objective of destabilizing the fat cells, breaking their
        structures, so that later there is a new organization and that it is more uniform and
        more aesthetically pleasing. May or may not use cream, as well as many others
        materials.<br/><br/>
        Therefore, lymphatic drainage is generally used in situations where there is
        edema, swelling and modeling massage is used to model, usually areas where
        there is fat.<br/><br/>
        How to know which one to use? It is best to be evaluated by a physical therapist to
        so that he can decide which type of manual technique is best suited to his case
        in particular`,
    },
    pregnancy: {
      img: "/images/blog/pregnancy.webp",
      createdAt: "30/08/2022",
      createdBy: "Phisioterapist Ana Sofia Ventura",
      title: "Weight in pregnancy",
      text: `
        <p>The later in the course of pregnancy, the greater the weight gain of the pregnant woman.</p>
        <p>The first trimester should be the one in which the weight gain &eacute; less significant.</p>
        <p>At the end of pregnancy, a woman should have a weight gain of between 11 and 16 kg.<br />In this &ldquo;package&rdquo; is &aacute; the baby&eacute; and the mother.</p>
        <ul>
          <li>4 to 5kg of this increase belongs to the baby; together with the placenta and amniotic fluid (for an &ldquo;average&rdquo; baby)</li>
          <li>There are between 7 and 12 kg left for the mother: the uterus, the breast and the circulating fluids <br/> should increase, as well as the fat reserves.</li>
        </ul>
        <p>Of these components, the only one that we can change, in order to maintain the ideal weight gain<br />for pregnancy, is&aacute; that of fat reserves.</p>
        <p>Therefore, weight gain varies with the mother's BMI.</p>
        <ul>
          <li>Women with a BMI < normal may have a greater weight gain</li>
          <li>Women with a > normal BMI should have less weight gain</li>
        </ul>
        <p>A woman doesn't need to eat for two, contrary to the popular saying.</p>
        <p>I remind you that monitoring during pregnancy is essential for everything to go well.</p>
        <p>In an ideal situation, a woman should consult a physical therapist and a nutritionist
        even before design in order to have a greater number of tools for
        knowledge.</p>
        <p>As we do not live in an ideal world, I leave you this post here for you or for anyone
        you think you need to read it.</p>
        <p>Take care of the most important person in your lives, you!</p>
        <p>In the first 3 months, the pregnant woman should not gain much weight.
        However, it is at this stage that many women gain much of their fat mass.
        Why?</p>
        <p>On the one hand, because of the old saying that a pregnant woman should eat for two what she
        makes the pregnant woman apologize for the excesses she has committed.</p>
        <p>On the other hand, in the first few months, the growth of the fetus is not significant, which makes
        with which the woman, so that her belly can be noticed and she is able to show her pregnant status,
        eat more.</p>
        <p>What can you do to prevent excess weight in pregnancy?</p>
        <p>Physiotherapy can design a suitable exercise plan for each woman and
        that they can perform during pregnancy. Pregnancy is a state of transformation
        in which all physiological systems undergo changes. There will be changes at the
        urinary, cardiorespiratory, musculoskeletal, among others. The exercises proposed by the
        physiotherapists look not only at the current state of pregnancy but also at the later stage
        of postpartum. In addition to taking overweight into account, they are also planned to
        prevent pregnancy-related complications, for example with urinary issues,
        gynecological, respiratory, muscular…</p>
        <p>Nutrition is extremely important because in addition to preventing excess weight,
        provides an adequate food plan for each woman who, in addition to providing
        that adapt to the mother's bodily and physiological changes also take into account the
        nutritional needs of the developing fetus.</p>
        `,
    },
    pelvic: {
      img: "/images/blog/pelvic.webp",
      createdAt: "19/01/2023",
      createdBy: "Phisioterapist Ana Sofia Ventura",
      title: "How to relax the Pelvic Floor?",
      text: `
        <p>The relaxation of the pavement is as or more important than its contraction. IT IS
        It is important to realize that relaxation is not the same as weakness. Accordingly
        with infopedia relaxation is:</p>
        <ol>
        <li>act or effect of relaxing or relaxing</li>
        <li>distension</li>
        <li>serenity, relaxation</li>
        <li>looseness</li>
        <li>depravity, lawlessness</li>
        <li>sloppiness, sloppiness</li>
        </ol>
        <p>This definition seems a little contradictory because it refers to feelings so much
        positives as well as negatives. For the purposes of muscle contraction we will consider that the
        relaxation is the initial state, a neutral position of normal tension. Similarly
        so that contraction is not the same as tension, relaxation is not the same as
        weakness. Muscles are supposed to have tension, but this must be within a
        normal pattern. A muscle contraction is “muscle work,” whether or not
        imply movement. Therefore, relaxation is when the muscle is not in
        contraction, that is, it is not working, it is not making an effort. When there is a contraction
        maximum, there is a greater probability of maximum relaxation and when there is
        maximal relaxation, there is a greater likelihood of maximal contraction.</p>
        <p>So, if the pelvic floor muscles have the ability to return to their
        initial position, without being in contraction, they will be able to contract more.
        Orgasms are excellent examples where when there is a strong contraction (the
        orgasm is a series of rhythmic contractions) there is also a maximum relaxation of
        the whole body.</p>
        <p>However, in the pelvic floor, the act of relaxing also occurs when we urinate
        or we defecate. That is, it is the relaxation of the pelvic floor that allows us to perform
        our needs. Relaxation should be seen as something positive.</p>
        <p>Relaxation should be voluntary and controlled. We're supposed to make it
        just relax the pavement`,
    },
  },
  massage: {
    title: "Massage <br/> at your place",
    lastMinute: "at the last minute",
    bookNow: "Book now",
    formLink: "https://forms.gle/APKKNrKxyVpWcEuU9",
    knowMore: "Find out more",
    avaliableService: "Service available in Madeira Island",
    only: "Only",
    ideal: {
      header: "Ideal for:",
      relax: "Those who want to relax",
      hardDay: "Had a tough day",
      lastMinute: "Did not book in advance",
      pain: "Pain in the neck",
      tension: "You want to resolve the tension as quickly as possible",
    },
  },
  author: "Author",
  date: "Date",
  contactUs: {
    send: "Send",
    messageSuccess: "Sent, we will be in touch soon",
    name: "Name",
    email: "Email",
    phone: "Phone",
    message: "Message",
    close: "Close",
    removeConsent: "Withdrawal of consent to data storage and processing.",
    rgdpConsent:
      "I authorize the storage and processing of data for the evaluation, and other possible future reserves.",
  },
  categories: {
    womenMom: "Women & Mom",
    baby: "Baby",
    elderly: "Elderly care",
    wellness: "Wellness",
    help: "Problems physiotherapy can help with.",
    muscleTension: {
      name: "Muscle Tension",
    },
    swelling: {
      name: "Swelling",
    },
    contractures: {
      name: "Contractures",
    },
    posturalAlterations: {
      name: "Postural Alterations",
    },
    colics: {
      name: "Colics",
    },
    mobility: {
      name: "Mobility",
    },
    breathingDifficulties: {
      name: "Breathing Difficulties",
    },
    congenitalTorticollis: {
      name: "Congenital Torticollis",
    },
    developmentDisorders: {
      name: "Development Disorders",
    },
    brachialPlexusInjury: {
      name: "Brachial Plexus Injury",
    },
    plagiocephaly: {
      name: "Plagiocephaly",
    },
    urinaryIncontinence: {
      name: "Urinary Incontinence",
    },
    painDuringIntercourse: {
      name: "Pain During Intercourse",
    },
    pelvicOrganProlapse: {
      name: "Pelvic Organ Prolapse",
    },
    pelvicPain: {
      name: "Pelvic Pain",
    },
    difficultyReachingOrgasm: {
      name: "Difficulty Reaching Orgasm",
    },
  },
  phoneInfo: "Call to national mobile network",
  reviews: {
    natalyBellomia: {
      text: `
        I tried the home massage and really enjoyed it. Excellent professionalism and knowledge. I recommend!
        `,
    },
    carolinaGomes: {
      text: `
        As I am a student on the move, I always have a hard time finding reliable physiotherapists that can accompany me both in Lisbon and Madeira. But since I met VenturaCare I always receive personalized attention to my needs, both in the island and in Lisbon. Service and communication are 5 stars! Regarding the prices, I also consider them fair for the work, i.e., great quality/price!
        `,
    },
    elisaVeronica: {
      text: `
        Loved the experience! To repeat without a doubt 😍
        `,
    },
  },
  home: {
    networkOf: "Network of",
    wellness: "Well-being",
    physiotherapists: "physiotherapists",
    transformYourLife: `Prepare your hotel to offer an excellent experience to your guests with our high-quality massages. Provide unparalleled moments of relaxation and well-being during their stay, with specialized therapists and techniques that ensure maximum comfort and revitalization.`,
    getInTouch: "Get in Touch",
    weProvide: "Home care services",
    weProvideDescription:
      "Ventura Cuida ensures your care, well-being, and independence. Trust us to take care of your health and happiness, all within the comfort of your home. Embark on an exceptional care experience.",
    blogExplore:
      "Explore our blog for expert advice, tips, and inspiration on optimizing your well-being.",
    seeWhatOthersSay: "See what others clients say",
    freeEvaluation: "FREE EVALUATION",
    takeCharge: "Take charge of your well-being. Get a free evaluation",
    appointment: "Book an appointment",
    areYouBusiness: "Do you own a company?",
    guestExperience: "Elevate guest experiences with premium therapies.",
    relaxationWellBeing: `Discover opportunities to enrich and complement your services, building a partnership that promises to delight your customers through physiotherapy and massages in your business.`,
    contactUs: "Contact us",
    letsDoBusiness: "Let’s Do Business Together",
    wellBeing: {
      title: "Well-being",
      description1: "Tension and muscle knot",
      description2: "Swelling",
      description3: "Postural changes",
    },
    elderly: {
      title: "Health for the Elderly",
      description1: "Mobility",
      description2: "Liquid Retention",
    },
    woman: {
      title: "Woman & Mother",
      description1: "Urinary incontinence",
      description2: "Pain in sexual intercourse",
      description3: "Pelvic Organ Prolapse",
      description4: "Difficulty reaching orgasm",
    },
    phisioterapist: "Phisioterapist Ana Sofia Ventura",
    clinicalDirector: "Clinical Director",
  },
  aboutUs: {
    whatIs: "What is VenturaCuida?",
  },
  recomendations: {
    description:
      "Elevate your physiotherapy experience with essential products! Discover curated items, from massage tools to relaxation aids, designed to complement your therapy sessions. Shop now and embrace the true potential of your wellness!",
  },
  therapist: {
    workUs: "Work with us, as",
    workWithUs: "Work with us",
    physiotherapist: "Physiotherapist",
    massagist: "Massagist",
    location1: "We are waiting for you in",
    location2: "Lisbon and",
    location3: "Madeira Island",
    paymentFair: "Fair payment",
    nonExclusivity: "No exclusivity",
    flexibility: "Flexible hours",
    airtable: "We provide Airtable template for your freelancing services",
  },
  consent: {
    authorization: "I have read and agree to the Data Protection and Privacy Policy, as well as the Terms of Service.",
    remove: "Withdraw your consent.",
  },
  massage: "Request a massage",
  sector: {
    hotel: "Hotel",
    gym: "Gym",
    "mother-child-center": "Mother & Child center",
    "medical-clinic": "Medical Clinic",
    insurance: "Insurance",
  },
  policy: {
    privacy: {
      id: 'Privacy',
      content: `<p>Ventura Cuida Privacy Policy</p>

      <p>Ventura Cuida is committed to protecting your privacy. This privacy policy explains how we collect, use, and protect the personal information you provide to us when using our website.</p>
      
      <p><strong>1. Information Collected</strong></p>
      
      <p>All personal information provided will be used to make your visit to our website as productive and enjoyable as possible. We guarantee the confidentiality of all data provided by users. The personal information collected may include your name, email, phone number, address, and date of birth, among others. The mandatory data provided in the registration form are necessary for the provision of our services. The omission or inaccuracy of this data is the sole responsibility of the user and may result in the refusal to provide the service.</p>
      
      <p><strong>2. Use of Information</strong></p>
      
      <p>Users' personal data will be processed and stored electronically for use in the contractual and/or commercial relationship with each user.</p>
      
      <p><strong>3. Users' Rights</strong></p>
      
      <p>According to applicable law, we guarantee the user the right to access, rectify, update, and object to the use of their personal data. To exercise these rights, you can contact us via email at <a href="mailto:venturacuida@gmail.com">venturacuida@gmail.com</a>.</p>
      
      <p><strong>4. Cookies and Similar Technologies</strong></p>
      
      <p>We use cookies to store information, such as personal preferences, during your visit to our website. Users can choose to disable cookies in their browser settings, but this may affect the interaction with the site.</p>
      
      <p><strong>5. Advertisements and Browsing Information</strong></p>
      
      <p>We collect information contained in advertisements, including IP address, internet service provider, browser used, and pages visited. This information is used to improve the user experience and is not shared with third parties.</p>
      
      <p><strong>6. Links to Third-Party Sites</strong></p>
      
      <p>Our website may contain links to third-party sites. Our privacy policy does not apply to these sites, so we recommend reviewing the privacy policies of these sites before providing any personal information.</p>
      
      <p><strong>7. Changes to the Privacy Policy</strong></p>
      
      <p>We reserve the right to change this privacy policy without prior notice. We recommend checking this page regularly to stay updated on any changes.</p>
      
      <p>By using our website, you agree to this privacy policy.</p>      
      `
    },
    protection: {
      id: 'Data Protection',
      content: `
      <p>Ventura Cuida Data Protection Policy</p>

<p>At Ventura Cuida, we are committed to protecting your privacy. As controllers of your personal data, we will collect and store it carefully.</p>

<p>The personal data we collect will be processed by us, along with other entities, if applicable. Our commitment is clear: we will use your data only for necessary purposes, such as providing services in the healthcare field and evaluating the service provided. Additionally, we may use your data to fulfill legal obligations, such as tax filing or debt collection. In this context, we may share your data with subcontracted entities for service provision, as per established contracts, or with other third-party entities, if necessary for legal or judicial reasons.</p>

<p>Patient health data will be treated with the utmost confidentiality and only by authorized healthcare professionals. Under certain circumstances provided for by law, they may be shared with the patient's family members.</p>

<p>If you wish for the services provided to be covered by your health insurance, we may share your personal data, including health-related data, with your insurance company or healthcare subsystem. It is important to note that these entities are also subject to confidentiality obligations.</p>

<p>In the case of a user or patient being a minor or incapacitated, authorization for the processing of their data must be given by the holder of parental responsibility or guardianship.</p>

<p>Your personal data necessary for the provision of healthcare will be retained in accordance with applicable law.</p>

<p>Under the law, we guarantee you the right to access, update, rectify, transfer, and object to the processing of your personal data. To exercise these rights, you may contact us by phone or email.</p>

<p>At Ventura Cuida, we take all necessary technical and organizational measures to ensure the security and confidentiality of your personal data. Our employees and service providers are required to respect confidentiality, and we implement filing methods that ensure data access only by authorized individuals.</p>

<p>For more information on how we process your data, please refer to our Privacy Policy or contact us via email at <a href="mailto:venturacuida@gmail.com">venturacuida@gmail.com</a>.</p>

      `
    },
    terms: {
      id: 'Terms & conditions',
      content: `
    <p>Overview</p>

<p>The domain <a href="http://www.venturacuida.com">www.venturacuida.com</a> is managed by Ventura Cuida, Lda, who is responsible for the management of this website, including all information, tools, and services available to users. By using our services or visiting our website, you agree to the terms, conditions, policies, and notices set forth herein.</p>

<p>Please read the Terms and Conditions of Service carefully before using our website or requesting any healthcare service. By accessing or using any part of the site, you agree to these terms and conditions. If you do not agree to all the terms and conditions, you should refrain from browsing the site or requesting services.</p>

<p>You can review the most current version of the Terms and Conditions of Service on this page. We reserve the right to update, change, or replace any part of the Terms and Conditions of Service by posting updates and/or changes to our website. It is your responsibility to check this page periodically for changes. Your continued use of or access to the website following the posting of any changes constitutes acceptance of those changes.</p>

<p>Purpose</p>

<p>The Ventura Cuida website is intended to provide information about home healthcare services, allowing users to access these services through electronic or telephone means, in accordance with the terms and conditions described herein.</p>

<p>Access to the Service</p>

<p>Appointment scheduling is available to users aged eighteen years or older. Individuals under the age of eighteen must obtain authorization from their legal representatives. The data provided by the user is legally recognized and mandatory for the completion of services. We reserve the right to refuse service to anyone for any reason at any time.</p>

<p>All services of Ventura Cuida are subject to local availability.</p>

<p>Services can be canceled without charge with a minimum of 24 hours' notice before the scheduled date and time. Cancellations made after this period will incur a fee. If the therapist travels to the location and the client is unable to receive the massage or forgets, the full price will be charged.</p>

<p>Prices</p>

<p>Prices for our services are subject to change without notice. We reserve the right to modify or discontinue services at any time without notice.</p>

<p>Data Transmission</p>

<p>The user agrees to provide the necessary data to create a user account and to access the healthcare services provided. You must keep the data updated to ensure the proper provision of services. All transmitted data is protected by digital certification to ensure its security.</p>

<p>For more information on the submission of personal information, please refer to our Privacy Policy.</p>

<p>Service Information and Content</p>

<p>We strive to ensure that all information presented is correct and up-to-date. However, in exceptional cases, errors or service unavailability may occur due to circumstances beyond our control.</p>

<p>All information about prices, products, specifications, and services is subject to change at any time.</p>

<p>Responsibilities</p>

<p>All services offered by Ventura Cuida comply with Portuguese legislation and are regulated by the Health Regulatory Authority. We guarantee adequate levels of site security, but we are not responsible for damages resulting from external factors, such as communication interruptions or hardware failures.</p>

<p>User Obligations</p>

<p>The user agrees to provide correct and up-to-date information and not to use false identities. If the provided data is incorrect or insufficient, the responsibility lies with the user.</p>

<p>In order to ensure the continuity of services and the privacy of healthcare professionals, any direct telephone contact must be made through Ventura Cuida's customer support service and is subject to availability.</p>

<p>Appointment Scheduling and Treatments</p>

<p>Appointments can be requested by phone or through other available contact methods. The user must create a client account and provide patient data to access the services.</p>

<p>Cancellation Policy</p>

<p>Sessions must be canceled with a minimum of two hours' notice through the Ventura Cuida portal or by direct phone contact. Failure to comply with this deadline may result in a cancellation fee.</p>

<p>Service Evaluation</p>

<p>Users are encouraged to evaluate the services provided through evaluation forms or phone calls. This process is part of our commitment to continuous improvement in the quality of our services.</p>

<p>Dispute Resolution</p>

<p>In the event of a dispute, users may resort to one of the available Consumer Conflict Arbitration Centers. More information is available on the Consumer Portal.</p>

<p>Changes to the Terms and Conditions of Service</p>

<p>Users can review the most current version of the Terms and Conditions of Service at any time on this page. We reserve the right to update, modify, or replace any part of these terms and conditions by posting on the website.</p>

<p>Contact Information</p>

<p>Any questions regarding the Terms and Conditions of Service can be sent to the email address <a href="mailto:venturacuida@gmail.com">venturacuida@gmail.com</a>.</p>

    `},
  },
  whatsApp: {
    openWeb: 'Open on the web',
    openPhone: 'Open on your phone',
    fastResponse: 'We respond in 10 minutes'
  },
  preAppointment: {
    title: 'Pre-Appointment',
    personalInfo: 'Personal information',
    name: 'Name',
    nameRequired: 'Name is required',
    phone: 'Phone',
    phoneNumber: 'Phone number',
    phoneNumberRequired: 'Phone number is required',
    email: 'Email',
    emailRequired: 'Email is required',
    birthday: 'Birthday',
    birthdayRequired: 'Birthday is required',
    gender: 'Gender',
    genderRequired: 'Gender is required',
    woman: 'Woman',
    men: 'Men',
    medicalConditionText: 'Medical condition',
    paymentCashInfo: `Please check any condition that applies to you. <br />
                  From the following list, please check any condition that applies to you.<br />
      Your honest response is very important to ensure safety conditions.<br />
        Not all the following conditions are absolute contraindications for a massage, but they are situations that we should be aware of and perhaps perform the massage with special care.`,
    pleaseMedicalCondition: 'Please tell us other medical conditions you have.',
    otherConditions: "Other conditions",
    otherIsRequired: 'Other is required',
    preferences: 'Preferences',
    areasOfTheBody: 'Areas of the body you would like us to focus on during the massage',
    massagePreferences: 'Massage preference',
    preferencesOptions: {
      pressure: 'With lot of pressure',
      toRelax: 'To relax',
      swollen: 'I feel swollen and would like a draining massage',
      pain: 'Specific pain that you would like resolved'
    },
    extraInformation: 'Extra information',
    relevantInformation: 'Let us know any relevant information for the massage.',
    information: 'Information',
    termsAndConditionsText: 'Consent and Terms of conditions',
    checkboxsChecked: 'All checkboxes must be checked',
    reset: 'Reset',
    submit: 'Submit',
    medicalConditions: {
      pregnancy: 'Pregnancy',
      injuries: 'Injuries or fractures within the last 6 months',
      uncontrolledHeart: 'Uncontrolled heart disease or blood pressure problems',
      deepVein: "Deep vein thrombosis (DVT)",
      bloodClotting: "Blood clotting disorders",
      skinInfections: "Skin infections, open wounds, or rashes",
      severeVaricose: "Severe varicose veins",
      osteoporosis: 'Severe osteoporosis',
      cancer: 'Cancer',
      others: 'Others'
    },
    areasBody: {
      feet: 'Feet',
      legs: 'Legs',
      back: 'Back',
      abdomen: 'Abdomen',
      neckAndHead: 'Neck and head',
    },
    termsAndConditions: {
      acceptReceiveMassage: 'I confirm and accept to receive a massage from one of the therapists, from VenturaCuida',
      massagePurpose: 'I understand and accept that the services provided are strictly therapeutic and for relaxation purposes; no sexual services are offered.',
      agreeToRespect: "I agree to respect all therapists and refrain from any inappropriate, suggestive, or sexual behavior.",
      inappropriateBehaviour: "I understand that any inappropriate behavior will result in the immediate termination of the service.",
      terms: "I have read and agree to the Data Protection and Privacy Policy, as well as the Terms of Service.",
    },
    success: 'Pre-appointment done with success',
    respondToAll: 'Please, respond to all the required fields',
  },
  errorHappened: 'Error happened, contact-us by email',
  canLoggin: 'You can now loggin',
  submit: 'Submit',
  recoverPassword: 'Recover password',
  reservationsMade: "Here you can access all the reservations made at your facilities.",
  askForPassword: "If you don't have a password yet, request one bellow.",
  partnership: "Partnership",
  forgotPassword: "I forgot the password, or I don't have a password",
  login: "Login",
  close: "Close",
  womanHealth: "Women's health"
};

export default enLang;
