import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import NoPage from "./pages/Layout/NoPage";
import Layout from "./pages/Layout";
import { internationalize } from "react-internationalization";
import { Providers } from "./contexts";
import { Home } from "./pages/Layout/Home";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

function renderDom() {
  const InternationalApp = internationalize(App);

  const container = document.getElementById("root");
  const rootContainer = createRoot(container);
  rootContainer.render(
    <Providers>
      <InternationalApp />
    </Providers>
  );
}

renderDom();
