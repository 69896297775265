import React, { useEffect, useState } from "react";
import { useAnchorScroll } from "../../hooks/useAnchorScroll";
import { Desktop } from "./Desktop";
import { useBreakpoints } from "react-match-breakpoints";
import { Mobile } from "./Mobile";

export function Header() {
  useAnchorScroll();
  const { tabletDown } = useBreakpoints();
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const event = (event) => {
      setScrollY(window?.scrollY);
    };

    window.addEventListener("scroll", event);
    return () => window?.removeEventListener("scroll", event);
  }, [setScrollY]);

  return tabletDown ? (
    <Mobile {...{ scrollY, setScrollY }} />
  ) : (
    <Desktop {...{ scrollY, setScrollY }} />
  );
}
