import hexRgb from "hex-rgb";
import { BONDI_BLUE } from "../../../../constants.js/index.js";
import ImageWithText from "../../../../components/ImageWithText/index.js";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { Text } from "react-internationalization";
import { getBlogContent } from "../../Blog/index.js";
import { Typography } from "@mui/material";
import { useBreakpoints } from "react-match-breakpoints";

const DesktopImage = ({ children, setIsHover, id, img, onClick }) => {
  const navigate = useNavigate();

  return <ImageWithText
    key={id}
    onMouseEnter={() => setIsHover(true)}
    onMouseLeave={() => setIsHover(false)}
    imageStyle={{
      padding: 0,
      width: 250,
      height: 150,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      backgroundImage: `linear-gradient(
        0deg,
        ${hexRgb(BONDI_BLUE, { format: "css", alpha: 0.5 })},
        ${hexRgb(BONDI_BLUE, { format: "css", alpha: 0.5 })}
      ), url(${img}
    )`,
    }}
    onClick={onClick}
  >
    {children}
  </ImageWithText>
}

const Mobile = ({ children, onClick }) => {
  return <div
    onClick={onClick}
    style={{
      padding: 5,
      borderRadius: 5,
      width: 250,
      height: 150,
      background: BONDI_BLUE,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
  }}>{children}</div>
}

export const BlogSection = ({ img, id, name, key }) => {
  const [isHover, setIsHover] = useState();
  const getBlogContentType = (type) => getBlogContent(id, type);
  const { mobile } = useBreakpoints();
  const navigate = useNavigate();

  const Root = mobile ? Mobile : DesktopImage;

  return (
    <Root {...{ 
      onClick: () => {
        navigate(`/blog/${id}`);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      },
    name,
    getBlogContentType,
    setIsHover,
    isHover,
    id,
    mobile,
    img
    }}>
      <motion.div
        style={{
          position: "absolute",
          width: 250,
          height: 150,
          opacity: 0.5,
          backgroundColor: BONDI_BLUE,

        }}
        initial={{ opacity: 0.3 }}
        whileHover={{ opacity: 0.7 }}
      ></motion.div>
      <motion.div
        layout
        transition={{
          layout: { duration: 0.3 },
        }}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          style={{
            zIndex: 100,
            fontWeight: 700,
            fontSize: 20,
            color: "white",
            height: "fit-content",
            width: "fit-content",
          }}
        >
          <Text id={name}></Text>
        </Typography>
        {isHover && (
          <div
            style={{
              zIndex: 10000,
              padding: "10px 0",
              display: "flex",
              gap: 10,
              flexDirection: "column",
              alignItems: mobile ? "flex-start" : "center",
            }}
          >
            <Typography variant="caption" style={{ color: "white" }}>
              <Text id="author" />: {getBlogContentType("createdBy")}
            </Typography>
            <Typography variant="caption" style={{ color: "white" }}>
              <Text id="date" />: {getBlogContentType("createdAt")}
            </Typography>
          </div>
        )}
      </motion.div>
    </Root>
  );
};
