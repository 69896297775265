import { createContext, useContext, useEffect } from "react"
import { setLanguage } from "react-internationalization";
import useLocalStorage from "use-local-storage";

const LanguageContext = createContext();

export const useLanguage = ()=> useContext(LanguageContext);

export function LanguageProvider({ children }) {
    const [languageLocal, setLanguageLocal] = useLocalStorage('language', 'pt');

    useEffect(()=> {
        setLanguage(languageLocal);
    },[languageLocal]);

    return (
        <LanguageContext.Provider
            value={
                {
                    language: languageLocal,
                    setLanguage: setLanguageLocal
                }
            }
        >
            {children}
        </LanguageContext.Provider>

    )
}

