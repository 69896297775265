import { Button, Fab, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import { BONDI_BLUE, CLOUD_BURST, blogs } from "../../../constants.js/index.js";
import VButton from "../../../components/V/VButton/index.js";
import { ClientsServices } from "./ClientsServices/index.js";
import { Content } from "./ProblemsWeSolve/index.js";
import { map } from "lodash";
import { BlogSection } from "./BlogSection/index.js";
import { motion } from "framer-motion";
import { useBreakpoints } from "react-match-breakpoints";
import { ClickableLogo } from "../../../components/ClickableLogo/index.js";
import { Text, translate } from "react-internationalization";
import { BRIGHT_GRAY } from "../../../constants.js/index.js";
import { BLACK } from "../../../constants.js/index.js";
import { WhatsAppWidget } from "../../../components/WhatsappWidget/index.js";

export const Home = () => {
  const theme = useTheme();
  const { tabletDown } = useBreakpoints();

  const [openContactModal, setOpenContactModal] = useState({});

  return (
    <div style={{ position: 'relative', display: "flex", flexDirection: "column" }}>
      <WhatsAppWidget />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: tabletDown ? "column" : "row",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: tabletDown ? "center" : "flex-start",
            marginTop: tabletDown ? 10 : 100,
            marginBottom: tabletDown ? 0 : 50,
            gap: theme.spacing(tabletDown ? 2 : 4),
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
          }}
        >
          {tabletDown && <ClickableLogo imgStyles={{ width: 150 }} />}
          <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
            <Typography
              id="top"
              component={"h1"}
              variant="h1"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                fontFamily: "Poppins",
                fontStyle: "normal",
                fontWeight: 700,
                fontSize: tabletDown ? "30px" : "74px",
                lineHeight: tabletDown ? "30px" : "74px",
                position: "relative",
                marginTop: 100,
              }}
            >
              <span style={{ color: CLOUD_BURST }}>
                <Text id={"womanHealth"}></Text>
              </span>
            </Typography>
          </div>

          <Typography
            component={"h3"}
            style={{
              maxWidth: tabletDown ? "400px" : "700px",
              textAlign: "left",
              fontSizeAdjust: '20px',
              color: CLOUD_BURST,
            }}
          >
            <Text id={"home.transformYourLife"} />
          </Typography>
        </div>
        <div style={{ position: "relative", alignSelf: "end" }}>
          <motion.img
            fetchpriority="high"
            initial={{ opacity: 0, x: -200 }}
            animate={{ opacity: 1, x: 0 }}
            src="images/ana.webp"
            style={{
              height: "auto",
              width: tabletDown ? "300px" : "400px",
              ...(tabletDown && { margin: "auto", paddingLeft: 16 }),
              alignSelf: tabletDown ? "center" : "flex-end",
            }}
            alt="Ana"
          ></motion.img>
          <div
            style={{
              zIndex: 100,
              display: "flex",
              flexDirection: "column",
              position: "absolute",
              bottom: 50,
              backgroundColor: BRIGHT_GRAY,
              padding: theme.spacing(1),
              borderRadius: 10,
            }}
          >
            <Typography fontWeight={700} variant="caption" color={BLACK}>
              <Text id={"home.clinicalDirector"}></Text>
            </Typography>
            <Typography variant="caption">
              <Text id={"home.phisioterapist"}></Text>
            </Typography>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
          marginTop: -20,
          padding: theme.spacing(1),
        }}
      >
        <ClientsServices />
        <div style={{ height: tabletDown ? 50 : 100 }}></div>
        <Content
          title={translate("home.weProvide")}
          description={translate("home.weProvideDescription")}
        />
        <div style={{ height: tabletDown ? 50 : 100 }}></div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: theme.spacing(2),
          }}
        >
          <Content
            title={"Blog"}
            description={translate("home.blogExplore")}
          />
          <div
            style={{
              display: "flex",
              gap: theme.spacing(5),
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            {map(blogs, ({ img, name, id }, index) => {
              return (
                <div key={index}>
                  <BlogSection {...{ img, name, id }} />
                </div>
              );
            })}
          </div>
          <div style={{ height: tabletDown ? 50 : 100 }}></div>
        </div>
      </div>
      <div style={{ height: tabletDown ? 50 : 100 }}></div>
      <div
        style={{
          backgroundColor: BONDI_BLUE,
          display: "flex",
          alignItems: "center",
          padding: "3vw",
          gap: theme.spacing(4),
          borderRadius: 20,
          flexWrap: "wrap",
          justifyContent: "center",
          marginLeft: tabletDown ? theme.spacing(2) : "auto",
          marginRight: tabletDown ? theme.spacing(2) : "auto",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: theme.spacing(1),
          }}
        >
          <Typography
            variant="body2"
            color={"white"}
            style={{ fontWeight: 700, fontSize: tabletDown ? 30 : 30 }}
          >
            <Text id="home.freeEvaluation"></Text>
          </Typography>
          <Typography
            variant="body2"
            color={CLOUD_BURST}
            style={{
              fontWeight: 700,
              maxWidth: tabletDown ? 300 : 500,
            }}
          >
            <Text id="home.takeCharge"></Text>
          </Typography>
        </div>
        <div style={{ display: "flex", flexDirection: "column", gap: 20 }}>
          <VButton
            variant={"contained"}
            color="secondary"
            onClick={() => {
              setOpenContactModal({ open: true });
            }}
          >
            <Text id="home.appointment"></Text>
          </VButton>
          <div style={{ display: "flex", height: 40, gap: 20 }}>
            <Button
              onClick={() => {
                window.open("tel:+351928095512", "_self", false);
              }}
            >
              <img
                src="images/icons/call.png"
                style={{ height: 30, width: 30 }}
                alt="messenger"
              ></img>
            </Button>
            <Button
              onClick={() => {
                window.open("https://m.me/venturacarefisioterapia");
              }}
            >
              <img
                src="images/icons/messenger.webp"
                style={{ height: 30, width: 30 }}
                alt="messenger"
              ></img>
            </Button>
            <Button
              onClick={() => {
                window.open("https://api.whatsapp.com/send?phone=351928095512");
              }}
            >
              <img
                src="images/icons/whatsapp.webp"
                style={{ height: 30, width: 30 }}
                alt="whatsapp"
              ></img>
            </Button>
          </div>
        </div>
      </div>
      <div style={{ height: tabletDown ? 50 : 100 }}></div>
    </div >
  );
};
